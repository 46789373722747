import React from "react";

const Button = ({ type, className, id, onClick, buttonText, disabled, value , src , imgClass}) => {
  return (
    <button
      type={type}
      className={className}
      id={id}
      onClick={onClick}
      disabled={disabled}
      value={value}
    >
      {buttonText}
      {src ? <img src={src} alt="" className={imgClass}/> : ''}
    </button>
  );
};
export default Button;

