import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

// import '../../assets/css/AccordionFaq.scss';

const AccordionFaqQstcV1 = ({ accordionContent }) => {
 
  const FaqString = accordionContent.map((contents, index) => {
    return (
      <div
        className='faq__accordion-item'
        key={index}>
        <Accordion.Item
          // className='accordion-item'
          eventKey={index}>
          <Accordion.Header className='faq__accordion-title'>{contents.heading}</Accordion.Header>
          <Accordion.Collapse
            eventKey={index}
            id={`accor-${index}`}
            className='accordion-collapse collapse'>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: contents.content }}></div>
            </Accordion.Body>
          </Accordion.Collapse>
        </Accordion.Item>
      </div>
    );
  });
  return (
    <section className="faq">
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-8 offset-lg-2">
          <h4 className="text-center mb-4">
            <b>Your questions answered</b>
          </h4>
          <Accordion className="accordion" id="accordionExample">
            {FaqString}
          </Accordion>
        </div>
      </div>
    </div>
  </section>
  );
};

export default AccordionFaqQstcV1;
