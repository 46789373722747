export const SplitFaq_OV50_CHBQS_V2_7 = [
  {
    heading: 'When will my Over 50s Life Cover policy pay out?',
    content:
      `<p>This policy is designed to pay out when you die. But you need to have your policy in place for a minimum amount of time before you’re fully covered. This is called your qualifying period.</p>
      <p>Our standard qualifying period is two years. Answering the medical questions we ask could change this to just six months.</p>
      <p>If you decide to buy Over 50s Life Cover, your qualifying period will be confirmed on your Policy Schedule.</p>`,
  },
  {
    heading: 'How much does Over 50s Life Cover cost?',
    content:
      `<p>You can choose how much you’d like your monthly payments to be but this will affect how much your payout will be.</p>
      <p>Other factors like your age and whether you answer the medical questions will also be factored in to your payout amount.</p>`,
  },
  {
    heading: 'Do I have to answer any medical questions?',
    content:
      `<p>You can choose to answer our two medical questions or not.</p>
      <p>If you don’t answer them, you’ll be offered a two-year qualifying period (the time your policy has to be in place before you're covered).</p>
      <p>If you choose to answer them, you might be offered an increased payout and only a six-month qualifying period.</p>`,
  },
  {
    heading: 'How long will I have to pay for this policy?',
    content:
      `<p>To maintain cover you’ll have to make the payments for your policy until you die. Depending on how long you live, you could end up paying more than your payout.</p>`,
  },
  {
    heading: 'Who will my payout go to?',
    content:
      `<p>You can choose who will get your payout when you die. It’s important to do this in advance by making specific arrangements.</p>
      <p>If you don’t choose who the money will go to, it’ll form part of what’s known as your estate. That means it will be counted as one of the things you owned.</p>
      <p>Your estate could be subject to inheritance tax and/or delayed in being paid out to your loved ones. That's because wills, probate and letters of administration can take a while to process.</p>
      <p>Making a nomination or putting your policy in trust are ways to makes sure your loved ones can get the money a little quicker.</p>
      <p>If you're diagnosed with a terminal illness after your qualifying period, you can choose to get half of your payout. We'll then pay the other half when you die.</p>`,
  },
  {
    heading: 'How can I make a nomination or put my policy in trust?',
    content:
      `<p>When you take out a policy we'll ask you who you want your payout to go to.</p>
      <h5><b>Making a nomination</b></h5>
      <p>Because we're a friendly society, you can make a nomination. This means you can name a person or people to receive up to £5,000 of your payout. They'll just need to complete the claim form and send us the death certificate, and we will pay them as quickly as possible. If the payout is more than £5,000, we'll pay the rest in line with our death claims process which may take a little longer.</p>
      <p>We'll let the person claiming know if we need to see other documents to claim the rest of the money.</p>
      <p>£5,000 is the nomination limit across all policies you hold with National Friendly.</p>
      <p>If you want to make a nomination, you'll need to complete and return a nomination form. We'll send you one in your welcome pack if you take out a policy or you can visit our Existing Members page to download one.</p>
      <h5><b>Placing your policy in trust</b></h5>
      <p>This will make sure that the payout goes to those you want to get the money and may have inheritance tax benefits. You'll need to check that any trust you set up allows you to choose the people you want to leave your money to.</p>
      <p>If you want to put your policy in trust, you'll need to complete and return a trust form. You may want to get help from a solicitor to do this. You can find out more on our Existing Members page.</p>`,
  },
  {
    heading: 'What other benefits do I get with an Over 50s Life Cover policy?',
    content:
      `<p>Your policy comes with free access to our virtual GP service. It’s called Friendly GP and it can be accessed over the phone or online 24 hours a day, 7 days a week.</p>
      <p>We’ll give you the full details when you take out a policy or you can go to our Existing Members page to find out more.</p>`,
  },
  {
    heading: 'How do I claim on my policy?',
    content:
      `<p>If you are looking to make a claim, please head over to our Claims page. There you will find more information on how to contact us and how we can help guide you through the process.</p>`,
  },
];

export const SplitFaq_QSTC_V1 = [
  {
    heading: 'What is Life Insurance?',
    content: `<p>Life insurance provides financial protection for your family if you pass away during the policy term. It helps cover debts and bills, ensuring your loved ones are supported.</p>
                                    <p>The payout is usually a lump sum, but some policies offer regular income options.</p>
                                    <p>Your premium depends on factors like age, health, lifestyle, policy type, and coverage duration.
                                    </p>`,
  },
  {
    heading: 'Why Consider Life Insurance?',
    content:
      `<p>A life insurance payout can offer several key benefits:</p>
                                    <ul>
                                        <li>Provide financial security for your loved ones</li>
                                        <li>Replace lost income that your family depends on</li>
                                        <li>Help settle any outstanding debts</li>
                                        <li>Cover your mortgage, ensuring your family can keep the home you’ve built</li>
                                    </ul>`,
  },
  {
    heading: 'How Does Life Insurance Work?',
    content:
      `<p>Life insurance pays out upon the policyholder’s death.</p>
                                    <ul>
                                        <li><strong> Joint policy:</strong> The payout typically goes to the surviving partner unless otherwise arranged. You may also split it into two single policies if you separate.
                                        </li>
                                        <li><strong>Single policy:</strong> The payout goes to your estate. You can specify a beneficiary by placing the policy in trust.
                                        </li>
                                        <li><strong> Family income benefit:</strong> Instead of a lump sum, your family could receive monthly payments for a set period.
                                        </li>
                                    </ul>
                                    <p>When choosing life insurance, consider:</p>
                                    <ul>
                                        <li><strong> Cover amount:</strong> Ensure it covers living costs, such as mortgage, bills, debts, childcare, education, and funeral expenses.</li>
                                        <li><strong>Policy length:</strong> Think about how long your dependents will need support.</li>
                                        <li><strong>Joint vs. single policy:</strong> Joint policies are cheaper but pay out only once, while single policies provide coverage even after one payout.</li>

                                    </ul>`,
  },
  {
    heading: 'What You Should Know:',
    content:
      `<ul>
                                        <li><strong> Suicide or self-inflicted injury:</strong> Your policy may not cover these, or may have conditions, such as requiring 12 months to pass since the policy was taken out or the cover was increased.</li>
                                        <li><strong>False information:</strong> If you provide incomplete, inaccurate, or untruthful information when applying, your provider may deny the payout.</li>
                                        <li><strong>Total disability:</strong> Most policies won’t cover total disability unless extra coverage is added.</li>
                                        <li><strong>Terminal illness:</strong> Some policies offer early payouts if diagnosed with a terminal illness and given less than 12 months to live.</li>
                                        <li><strong>Missed payments:</strong> If you stop paying premiums, your policy will be cancelled, and you won’t receive a payout.</li>
                                    </ul>`,
  },
  {
    heading: 'How Much Life Insurance Do I Need?',
    content:
      ` <p>The right amount of life insurance depends on your personal situation. Generally, the more coverage you have, the higher your premium, but underestimating your needs could leave your loved ones financially vulnerable.</p>
                                    <p>Consider how your circumstances might change. For example, if a non-working partner passes away, you may face extrachildcare or eldercare expenses.</p>
                                    <p>If a working partner passes, you might need to reduce your work hours to care for children or other dependents. </p>`,
  },
  {
    heading: 'What Does Life Insurance Cover?',
    content:
      `<p>Understanding what life insurance covers is crucial to ensuring your loved ones are supported after you're gone.</p>
                                    <p>Life insurance typically covers common causes of death, but some providers may exclude coverage for genetic conditions or illnesses such as cancer or heart disease.</p>`,
  },
  {
    heading: 'What Isn’t Covered by Life Insurance?',
    content:
      `<p>Life insurance policies generally do not pay out for deaths resulting from:</p>
                                    <ul>
                                        <li> Pre-existing conditions, including genetic disorders or health issues that were present before the policy began</li>
                                        <li> Abuse of drugs or alcohol</li>
                                        <li> High-risk activities, such as extreme sports</li>
                                        <li> Committing a crime or provoking an assault</li>

                                    </ul>
                                    <p> Be sure to review the policy's coverage, limits, and exclusions to make an informed decision before purchasing life insurance.
                                    </p>`,
  },
  {
    heading: 'How Much Could Life Insurance Pay Out?',
    content:
      `<p>The payout amount from a life insurance policy depends on the type of coverage and the amount you choose.</p>
                                    <p>In 2022, the average life insurance payout was nearly £73,600, according to the Association of British Insurers (ABI). However, your beneficiaries could receive more or less than this, depending on your premiums and
                                        the provider you select.</p>
                                    <p>You will be given a clear payout estimate when you receive a life insurance quote.</p>`,
  },
];

