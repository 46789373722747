import React, { useState, useEffect } from "react";
import { PhoneNumberConstantO2C } from "../Constants/PhoneNumberConstants";

const RingBaPhoneNumberO2C = (utmName) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();

  useEffect(() => {
    if (PhoneNumberConstantO2C[utmName] == undefined) {
      setPhoneNumber(PhoneNumberConstantO2C['default']);
    } else {
      setPhoneNumber(PhoneNumberConstantO2C[utmName]);
    }
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, '').slice(-10);
      if (digits.length === 10) {
        const formattedNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        setFormattedPhoneNumber(formattedNumber);
      }
    }
  }, [phoneNumber]);

  // console.log("formattedPhoneNumber: ", formattedPhoneNumber);
  console.log("utmName: ", utmName," | utmName phno: ",phoneNumber);
  return { phoneNumber, formattedPhoneNumber }
}

export default RingBaPhoneNumberO2C;