export const PhoneNumberConstants = {
    "default": "+448000554389",
    // "default_SPH": "+448000554389",
    "STAY_COVERED_HYB_OVER50_FB_JMS":"+448000554464",
    "STAY_COVERED_OC":"+448081649049",
    "STAY_COVERED_HYB_OVER50_GOOGLE_DISPLAY_JAMES":"+448000554464",
    "STAY_COVERED_HYB_OVER50_OUTBRAIN_JMS":"+448000554464",
    "STAY_COVERED_HYB_OVER50_TIKTOK_JMS":"+448000554464",
    "STAY_COVERED_HYB_OVER50_GOOGLE_SEARCH_JMS":"+448000554464",
    "STAY_COVERED_OVER50_GOOGLE_DEMAND_GEN_JMS":"+448000554464",
    "STAY_COVERED_OVER50_GOOGLE_P_MAX_JMS":"+448000554464",
    "STAY_COVERED_OVER50_GOOGLE_DSY_JMS":"+448000554464",
    "STAY_COVERED_OVER50_ADV_GOOGLE_DISPLAY_JMS":"+448000554464",
    "STAY_COVERED_OVER50_ADV_TIKTOK_JMS":"+448000554464",
    "STAY_COVERED_OVER50_ADV_FB_ADAM":"+448000554389",
    "STAY_COVERED_50PLUS_FB_ART":"+448000554216",
    "STAY_COVERED_OVER50_ADV_FB_JMS" : "+448000554464",
    "STAY_COVERED_OVER50_FB_GERSON" : "+448000554209",
    "STAY_COVERED_OVER50_ADV_FB_JMS_2" : "+448000554464",
    "STAY_COVERED_OVER50_TAB_JMS" : "+448000554464",
    "STAY_COVERED_OVER50_ADV_TABOOLA_JMS" : "+448000554464",
    "STAY_COVERED_OVER50_ADV_OUTBRAIN_JMS" : "+448000554464",
    "STAY_COVERED_50+_NEWSBREAK_JMS":"+448000554464",
    "STAY_COVERED_50PLUS_GOOGLE_ART":"+448000554216"
};

export const PhoneNumberConstantNotFor50 = {
    "default" : "+448000554464"
}

export const PhoneNumberConstantO2C = {
    "default" : "+448000554389"
}
