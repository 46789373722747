export const QSTC_V1_Chats = [
  {
    id: "1",
    question: ['Hi!',<>Before we get started, please confirm you were born <b>after 1974?</b></>],
    // specialcss: "verticleButton",
    options: [
      { id: "1", text: "Yes" },
      { id: "2", text: "No" },
    ],
  },
  {
    id: "5",
    question: ['Excellent, thank you.',
      `Are you looking for coverage for just you, or you and your partner?`,
    ],
    specialcss: "verticleButton",
    options: [
      { id: "11", text: "Me" },
      { id: "12", text: "Me & My Partner" },
    ],
  },
  {
    id: "11",
    question: ['Okay, great',
      `Do you smoke?`,
    ],
    // dummyTest: `Please check all answers that apply.`,
    // multiple: true,
    options: [
      { id: "27", text: "Yes" },
      { id: "28", text: "No" },
    ],
  },
  {
    id: "12",
    question: [`Have you had any of the following in last 5 years?` ,<><ul>
      <li>Cancer</li>
      <li>Heart Conditions</li>
      <li>Stroke</li>
    </ul></> ],
    options: [
      { id: "29", text: "Yes",  },
      { id: "30", text: "No" },
    ],
  },
  {
    id: "7",
    specialclass: "last_class",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳` ,
      `It looks like you qualify for life coverage starting at less than a cup of a coffee a day.` ,
      `To get the best possible price available, speak to one of our licensed agents.`,
      `This streamlines the process and only takes 2 to 3 minutes.`  
    ],
    options: [
      { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

    ],
  },
];