import React from 'react'
import { Link } from "react-router-dom"
import logo from "../../../assets/img/QSTC_V1/logo.png"

const Terms = () => {
  return (
    <>
      <>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <a href="index.html">
                  <img src={logo} height={45} />
                </a>
              </div>
            </div>
          </div>
        </header>

        <section className="privacy_terms">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <h2>Terms &amp; Conditions</h2>
                <p>
                  Stay Covered - Financial Services is an online referral and
                  introducer of FCA regulated Life Insurance products. We provide a
                  referral service to named regulated companies for the processing of
                  claims based on the information provided through this website.
                </p>
                <p>
                  The terms 'Stay Covered - Financial Services', 'us' or 'we' refers
                  to the owner of the website. The term 'you' refers to the user or
                  viewer of our website. The use of this website is subject to the
                  following terms of use:
                </p>
                <ol>
                  <li>
                    The content of the pages of this website is for your general
                    information and use only. It is subject to change without notice.
                  </li>
                  <li>
                    Neither we nor any third parties provide any warranty or guarantee
                    as to the accuracy, timeliness, performance, completeness or
                    suitability of the information and materials found or offered on
                    this website for any particular purpose. You acknowledge that such
                    information and materials may contain inaccuracies or errors and
                    we expressly exclude liability for any such inaccuracies or errors
                    to the fullest extent permitted by law.
                  </li>
                  <li>
                    Your use of any information or materials on this website is
                    entirely at your own risk, for which we shall not be liable. It
                    shall be your own responsibility to ensure that any products,
                    services or information available through this website meet your
                    specific requirements.
                  </li>
                  <li>
                    This website contains material which is owned by or licensed to
                    us. This material includes, but is not limited to, the design,
                    layout, look, appearance and graphics. Reproduction is prohibited
                    other than in accordance with the copyright notice, which forms
                    part of these terms and conditions.
                  </li>
                  <li>
                    All trademarks reproduced in this website, which are not the
                    property of, or licensed to the operator, are acknowledged on the
                    website.
                  </li>
                  <li>
                    Unauthorized use of this website may give rise to a claim for
                    damages and/or be a criminal offense.
                  </li>
                  <li>
                    From time to time this website may also include links to other
                    websites. These links are provided for your convenience to provide
                    further information. They do not signify that we endorse the
                    website(s). We have no responsibility for the content of the
                    linked website(s).
                  </li>
                  <li>
                    You may not create a link to this website from another website or
                    document without Stay Covered - Financial Services or Veriform Ltd
                    prior written consent.
                  </li>
                </ol>
                <p>
                  Veriform Ltd is a company registered in England &amp; Wales,
                  registration number 07195400 and registered at 145/147 Hatfield
                  Road, St. Albans, Hertfordshire, AL1 4JY, and is authorised and
                  regulated by the Financial Conduct Authority, firm reference number
                  690199.
                </p>
                <p>
                  Veriform Ltd or any of its trading styles are not an insurance
                  company nor able to offer any advice into any financial services
                  regulated products. Any terms and conditions may vary for all of our
                  partners.
                </p>
                <p>
                  We do not charge a fee for this service, but we do receive referral
                  fee directly from our partners following successful introductions
                  from this website. Any information we provide is for the purposes of
                  illustrating the products promoted on this site only and should not
                  be read as financial advice.
                </p>
                <p>
                  Any complaints that arise from contact originating from
                  communication from a named company or third party on this site is
                  the responsibility of Veriform Ltd should be communicated via our
                  complaint procedure stated on our site.
                </p>
                <p>
                  Any acceptance of a third party’s terms and conditions or privacy
                  policy is not accordance to Veriform Ltd and independent advise is
                  recommended before entering any third party terms and conditions or
                  privacy policies.
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="mt-5 text-center">
          <div className="container">
            <div className="offset-lg-1 col-lg-10">
              <div className="row mb-3">
                <div className=" py-2">
                  <h6>Disclaimer</h6>
                  <p>
                    Stay Covered - Financial is a trading style of Veriform Ltd and is
                    authorised and regulated by the FCA.
                  </p>
                  <p>
                    Subject to our terms and conditions Stay Covered - Financial will
                    process the information you provide us and may pass this on to our
                    named partners for marketing purposes.
                  </p>
                  <p>
                    Veriform Ltd is a company registered in England &amp; Wales,
                    registration number 07195400 and registered at 145/147 Hatfield
                    Road, St. Albans, Hertfordshire, AL1 4JY, Authorised and regulated
                    by the Financial Conduct Authority, firm reference number 690199.
                  </p>
                  <p>
                    Stay Covered - Financial is not an insurance company nor able to
                    offer any advice into any financial services regulated products.
                    Any terms and conditions may vary for all of our partners.
                  </p>
                  <p>
                    We do not charge a fee for this service, but we do receive a
                    commission directly from our partners following successful
                    introductions from this website. Any information we provide is for
                    the purposes of illustrating the products promoted on this site
                    only and should not be read as financial advice.
                  </p>
                  <p>Stay Covered - Financial © 2024</p>
                </div>
                {/* <div></div>
                <div>
                  <p>
                    <Link target="_blank" to="privacy">Privacy Policy</Link>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      </>

    </>
  )
}

export default Terms